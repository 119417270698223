import React from "react";
import "../../App.css";
import HeroSlide from "../../components/heroSlide/HeroSlide";
import "./home.scss";
import CityCarousel from "../../components/carousel/CityCarousel";
import OurAdv from "../../components/ourDv/OurAdv";
import TopDestinations from "../../components/topDestinations/TopDestinations";
import GetDiscount from "../../components/getDeiscount/GetDiscount";
import OurGallery from "../../components/ourGallery/OurGallery";
import WeAreTrusted from "../../components/weAreTrusted/WeAreTrusted";
import Video from "../../components/video/Video";
import Offer from "../../components/offer/Offer";
import Testimonials from "../../components/testimonials/Testimonials";
import { Link } from "react-router-dom";
import SelectTours from "../../components/selectTours/SelectTours";

const Home = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="__fw">
      <HeroSlide />

      <div className="__mw">
        <div className="we_dont_wrp">
          <div className="_we_dont">
            <h2>
              We don’t just sell travel – <em className="spFont" >we create connections. </em>
              Connections between people, cultures, and experiences that will
              stay with you forever.
            </h2>
          </div>
          <div className="we_dont_img">
            <div className="img_we">
              <img src={require("../../img/place_s.png")} alt="" />
            </div>
            <div className="text_we">
              <span>Have fun with us!</span>
              <Link to={"/offers"} onClick={scrollTop}>
                <p>explore group tours </p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="__mw">
        <div className="out_line"></div>
      </div>

      <div className="___tour_card">
        <div className="tour_crd_hd">
          <small className="subTag">Hottest tours</small>
          <h1>
            <em className="spFont" >The Journey Of A Lifetime: </em> <br /> Create Unforgettable Memories
          </h1>
          <p>
            Get ready to embark on the journey of a lifetime! Our travel agency
            is dedicated to crafting unforgettable <br /> experiences that will
            leave you with lifelong memories.
          </p>
        </div>
        <SelectTours />
        <div className="out_line_main">
          <div className="out_line"></div>
          <div className="subTag_name">
            <div className="subTag_name_wrp">
              <Link to={"/sri-lanka-tour-packages"}>
                <small onClick={scrollTop} className="subTag">
                  explore all Tours
                </small>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="__mw">
        <OurAdv />
      </div>

      <TopDestinations />

      <div className="out_line_main">
        <div className="out_line"></div>
        <div className="subTag_name">
          <div className="subTag_name_wrp">
            <small className="subTag">explore all places</small>
          </div>
        </div>
      </div>

      <div className="__mw">
        <GetDiscount />
      </div>

      <div className="__mw" style={{ padding: " 80px, 0px," }}>
        <div className="o_gl_head">
          <div className="o_gl_head_left">
            <small className="subTag">Our Gallery</small>
            <h1>
              <em className="spFont">Journey Of A Lifetime: </em> <br /> Create
              Unforgettable Memories
            </h1>
          </div>
          <div className="o_gl_haed_right">
            <div className=" o_gl_haed_right_wrp">
              <Link onClick={scrollTop} to={"/gallery"}>
                <small className="subTag">go to gallery</small>
              </Link>
            </div>
          </div>
        </div>
        <OurGallery />
      </div>

      <div className="__mw">
        <WeAreTrusted />
      </div>

      <Video embedId="YmXW3_KpGls" />

      <div className="__mw">
        <Testimonials />
      </div>

      <div className="__mw">
        <Offer />
      </div>

      {/* <div className="info_key">
        <p>
          colombo sri lanka, sri lanka tour packages, sri lanka tour, best tour
          packages in sri lanka, places to visit in sri lanka, sri lanka
          honeymoon packages, sri lanka travel package, sri lanka 5 days
          itinerary, sri lanka tour package, sri lanka trip, sri lanka tour
          itinerary, sri lanka tourism, sri lanka cheap tour packages, sri lanka
          itinerary 4 days, srilanka tour package, 5 day trip to sri lanka, 7
          days in sri lanka, sri lanka itinerary, sri lanka itinerary 1 week,
          sri lanka itinerary 6 days, sri lanka package holidays, sri lanka
          trip, cheap tour sri lanka, five days in sri lanka, holiday in colombo
          sri lanka, places to visit in sri lanka in 5 days, sri lanka holiday,
          sri lanka tour operators, sri lanka travel itinerary, sri lanka trip
          package, sri lanka trip planner, 4 day tour sri lanka, 4 days in sri
          lanka, 4 nights 5 days sri lanka package, day tours in sri lanka, sri
          lanka trip packages, sri lanka city tour, sri lanka 7 days tour
          package, sri lanka city tour, sri lanka ground tour package, sri lanka
          holiday itinerary, sri lanka in a week, sri lanka luxury tours, sri
          lanka tour itinerary, sri lanka vacation, srilanka tour package
          itinerary tour package to sri lanka from malaysia, sri lanka in 7
          days, dynasty travel sri lanka, place to visit in sri lanka, sri lanka
          honeymoon, sri lanka itinerary 7 days, sri lanka holidays, 6 day sri
          lanka tour, sri lanka tour packages from malaysia, freelance tour
          guide in sri lanka, sri lanka tour package from singapore, sri lanka 5
          day itinerary, tour package to sri lanka, honeymoon in sri lanka, sri
          lanka land tour packages, sri lanka honeymoon package, sri lanka
          travel packages from singapore, sri lanka resort tours from singapore,
          sri lanka tour singapore, travel sri lanka, sri lanka tourist
          attractions, sri lanka tour package singapore, srilanka tourism, sri
          lanka tourist attractions, places to visit sri lanka, sri lanka places
          of interest, 5 days in sri lanka, visit sri lanka tours, sri lanka
          package tour from singapore, sri lanka tours from malaysia
        </p>
      </div> */}
    </div>
  );
};

export default Home;
