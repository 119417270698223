import React, { useState } from "react";
import "./offer.scss";
import axios from "axios";

const Offer = () => {
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");

  const handleSend = async (e) => {
    e.preventDefault();
    const data = {
      email,
      promotionName: "Win tip to sri lanka",
      promoCode: "WIN",
    };
    try {
      const response = await axios.post("/win_trip_sri_lanka", data);
      if (response.status === 200) {
        setMessage("Thank you! You have successfully entered the contest.");
      }
    } catch (error) {
      setMessage("Oops! Something went wrong. Please try again.");
    }
    setEmail("")
  };
  return (
    <div className="offer">
      <div className="offer_info">
        <small>Promo</small>
        <span>
          {" "}
          <em className="spFont" >Win Dream Trip</em> to Sri Lanka
        </span>
        <p>
          Simply enter your email to join and be one step closer to your dream
          trip to Sri Lanka!
        </p>
        <form onSubmit={handleSend}>
          <input
            type="text"
            placeholder="Enter your email here…"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="sub_btn" type="submit">
            Join Now
          </button>
        </form>
        {message && <small>{message}</small>}
        <div className="lable">
          <span>Summer 2025</span>
        </div>
      </div>
      <div className="offer_img">
        <img src={require("../../img/galle-fort.jpg")} alt="" />
      </div>
    </div>
  );
};

export default Offer;
