import React, { Fragment, useState } from "react";
import Popup from "reactjs-popup";
import "./autoPopup.css";
import backImg from "../../img/wintriptosrilanka.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import seylontourlog from "../../img/seylontourlog.svg";

const AutoPopup = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");

  const handleSend = async (e) => {
    e.preventDefault();
    const data = {
      email,
      promotionName: "Win tip to sri lanka",
      promoCode: "WIN",
    };
    try {
      const response = await axios.post("/win_trip_sri_lanka", data);
      if (response.status === 200) {
        setMessage("Thank you! You have successfully entered the contest.");
         setTimeout(() => {
           setOpen(false);
         }, 3000);
      }
    } catch (error) {
      setMessage("Oops! Something went wrong. Please try again.");
    }
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="popup_wrp">
          <Popup open={open} closeOnDocumentClick onClose={closeModal}>
            <div className="modal_container">
              <div className="modal-content">
                <div className="modal-form">
                  <div className="modal_img">
                    <img
                      src={require("../../img/wintriptosrilanka.jpg")}
                      alt=""
                    />
                    {/* <div className="modal_head">
                      <h1>
                        Win a Dream Trip to <br /> <em>Sri Lanka </em>
                      </h1>
                      <h4>Experience the Paradise Island</h4>
                    </div> */}
                  </div>
                  <div className="modal_form_in">
                    <section className="mob_banner">
                      <img
                        src={require("../../img/Woman2-banner.jpg")}
                        alt=""
                      />
                    </section>
                    <section className="sec_top">
                      <img src={seylontourlog} alt="seylontour logo" />
                      {/* <h2>
                        Enter Now for a Chance to Win Dream Trip to Sri Lanka!
                      </h2> */}

                      <form onSubmit={handleSend}>
                        <p>
                          Simply enter your email to join and be one step closer
                          to your dream trip to Sri Lanka!
                        </p>
                        <input
                          type="text"
                          placeholder="Enter your email here…"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="sub_btn_pop" type="submit">
                          Join Now
                        </button>
                      </form>
                      {message && <small>{message}</small>}
                    </section>
                    <section className="sec_botm">
                      By joining, you agree to the seylontour Terms of Service
                      and to occasionally receive emails from us. Please read
                      our Privacy Policy to learn how we use your personal data.
                    </section>
                  </div>
                </div>
                <div className="close">
                  <span onClick={closeModal}>&times;</span>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default AutoPopup;
