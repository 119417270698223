import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./tourCheckuot.scss";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const TourCheckuot = () => {
  const { data } = useParams();
  const today = new Date().toISOString().slice(0, 7);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pax, setPax] = useState(1);
  const [children, setChildren] = useState("");
  const [infant, setInfant] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState(today);
  const [message, setMessage] = useState("");
  const [know, setKnow] = useState("");
  const [sucMessage, setSucMessage] = useState("");
  const [paymentRes, setPaymentRes] = useState("");
  const [supplement, setSupplement] = useState(false);
  const [supplementNo, setSupplementNo] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [price, setPrice] = useState(null);
  const [supplementPrice, setCommissionPrice] = useState(null);
  const [tourNames,setTourNames]=useState("");
  const [tripCode,setTripCode] = useState("")

  useEffect(() => {
    if (data) {
      const [priceValue, supplementValue, country,tourName] = data.split(",");
      setPrice(priceValue);
      setCommissionPrice(supplementValue);
      setSelectCountry(country);
      setTourNames(tourName);
    }
  }, [data]);

  const subTotal = price * pax;
  const totalCost = subTotal;

  // console.log(selectCountry);

  // console.log("supplement",supplement);
  // console.log("supplementPrice", supplementPrice);

  // console.log(price);
  // console.log(supplementPrice);


  const fixdate = ()=>{
     if (tourNames === "5day") {
       return `${date}-5th`;
     }
     if (tourNames === "7day") {
       return `${date}-7th`;
     }
     if (tourNames === "10day") {
       return `${date}-11th`;
     } else {
       return;
     }

  }

  const conformDate = fixdate()

  // console.log(conformDate);

  const handleTourName =()=>{
    if(tourNames === "5day"){
      return "DISCOVER 05-DAY TOUR IN SRI LANKA";
    }
    if (tourNames === "7day") {
      return "DISCOVER 07-DAY TOUR IN SRI LANKA";
    }
    if (tourNames === "10day") {
      return "DISCOVER 10-DAY TOUR IN SRI LANKA";
    }
    else{
      return
    }
  }

  const tourName = handleTourName()
 

  const calSup = () => {
    if (supplement === true) {
      return supplementPrice;
    } else {
      return 0;
    }
  };

  const singalSupplementCost = calSup();

  //  console.log("singalSupplementCost",singalSupplementCost);

  const calSupplementCost = () => {
    return supplementPrice * supplementNo;
    setSupplement("");
  };

  const supplementCost = calSupplementCost();

  // console.log("supplementCost", supplementCost);

  const childrenTotalCost = (price / 2) * children;

  const handleCheckboxChange = () => {
    setSupplement(!supplement);
  };

  const total =
    Number(supplementCost) +
    Number(singalSupplementCost) +
    Number(totalCost) +
    Number(childrenTotalCost);


    const payableTotal = ()=>{
      const precent = (total * 10 )/100
      return precent
    }

    const payableAmount = payableTotal()

    const totalPax = parseInt(pax) + parseInt(infant) + parseInt(children);

    // console.log(totalPax);

  const stripePromise = loadStripe(
    // "pk_test_51PRvdq07nvLLFkFAjonGXo9PeZBuarfc2pGZ5xctD3w6Jl3f7J6SH77U7NfTZV6SgLGiRYsddZMcZd3P9sMswFpA00cgNkre85"
    "pk_live_51PRvdq07nvLLFkFA5J591P6fHKQrXk32fywTQshYBZUcAdL1lSG8lOeQcupHdVgJeRdqnf2a0DEU6EzImptnESKy00Rbb6s3jr"
  );

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      const stripe = await stripePromise;

      const product = {
        id: tripCode,
        title: tourName,
        price: payableAmount,
        quantity: totalPax,
        country: selectCountry,
      };

      const res = await axios.post("/payment", {
        product,
      });
      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });
    } catch (err) {
      console.log("Stripe connection failed:", err);
    }
  };

  const makeInquiry = async (e) => {
    e.preventDefault();
    const bookingData = {
      name,
      email,
      phone,
      numberOfAudlt: pax,
      adultCost: pax * price,
      date: conformDate,
      numberOfInfant: infant,
      childrenCost: childrenTotalCost,
      country,
      supplementAmount: supplementCost || supplementPrice,
      tripCode,
      numberOfSupplement: (supplement ? true && 1 : 0 || supplementNo),
      tourName,
      advancePayment: payableAmount,
      totalTourValue: total,
      numberOfChildren: children,
      balance: total - payableAmount,
    };

    // console.log(bookingData);

    try {
      await axios.post("/group_tour_inquiry", bookingData);
      setSucMessage(
        "Thank you for inquiring about our tour. Our specialist will reach out to you soon."
      );
    } catch (error) {
      console.log(error);
    }
    setName("");
    setEmail("");
    setPhone("");
    setPax("");
    setDate("");
    setChildren("");
    setCountry("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // First, submit the inquiry data
    try {
      await makeInquiry(e);
    } catch (error) {
      console.log("Inquiry submission failed:", error);
      return; // Stop if inquiry fails
    }

    // Then, proceed with the payment
    try {
      await handlePayment(e);
    } catch (error) {
      console.log("Payment process failed:", error);
      return;
    }
  };

   useEffect(() => {
     const generateCode = () => {
       const uniqueNumber = Date.now().toString().slice(-6); // Get the last 6 digits of the current timestamp
       const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
       const code = `G - ${uniqueNumber}${randomNumber}`;
       setTripCode(code);
     };

     generateCode();
   }, [data]);

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="tours_head">
          <h1>Checkout</h1>
        </div>
        <div className="checkout">
          <div className="checkout_form" id="a">
            <form onSubmit={handleSubmit}>
              <div className="input_wrp">
                <small>Full Name *</small>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  placeholder="Your Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <small>Email *</small>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="Your Email Address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <small>Phone *</small>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={phone}
                  placeholder="Your Phone Number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <small>Country *</small>
                <input
                  required
                  type="text"
                  name="country"
                  id="country"
                  min={1}
                  value={country}
                  placeholder="Singaporean"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>

              <div className="input_wrp">
                <small>Number of Pax *</small>
                <input
                  required
                  type="number"
                  name="pax"
                  id="pax"
                  min={1}
                  value={pax}
                  placeholder="How many Pax will Travel"
                  onChange={(e) => setPax(e.target.value)}
                />
              </div>
              {pax <= 2 ? (
                <div className="_input_wrp">
                  <span>Single Supplement</span>{" "}
                  <input
                    type="checkbox"
                    checked={supplement}
                    onChange={handleCheckboxChange}
                  />
                </div>
              ) : (
                <div className="_input_wrp">
                  <span>Number of Single Supplement</span>{" "}
                  <input
                    type="number"
                    name="supplement"
                    id="supplement"
                    value={supplementNo}
                    onChange={(e) => setSupplementNo(e.target.value)}
                  />
                </div>
              )}

              <div className="input_wrp">
                <small>Number of Children 6 to 12 Years Old </small>
                <input
                 
                  type="number"
                  name="children"
                  id="children"
                  min={1}
                  value={children}
                  placeholder="How many Children will Travel"
                  onChange={(e) => setChildren(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <small>Number of Infant Below 6 Years Old </small>
                <input
                
                  type="number"
                  name="infant"
                  id="infant"
                  min={1}
                  value={infant}
                  placeholder="How many Infant will Travel"
                  onChange={(e) => setInfant(e.target.value)}
                />
              </div>

              <div className="input_wrp">
                <small>Month plan to travel</small>
                <input
                  style={{ width: "100%", padding: "10px" }}
                  type="month"
                  name="tourdate"
                  id="tourdate"
                  value={date}
                  required
                  placeholder="Date Plan to Travel"
                  onChange={(e) => setDate(e.target.value)}
                  min={today}
                />
              </div>

              <div className="termandd">
                <input type="checkbox" required />
                <span>
                  Check the box to confirm you've read and agree to our Terms
                  and Conditions and Privacy Policy.
                </span>
              </div>
              <button class="shopButton2" type="submit">
                Continue
              </button>
            </form>
          </div>
          <div className="__tour_info_sum" id="b">
            <div className="check_form">
              <div className="book_head">
                <h3>Booking Summary</h3>
              </div>
              <div className="__tour_info">
                <h4>{tourName}</h4>
                <p>
                  Starting Date: {date}-
                  {(tourNames === "5day" && "5th") ||
                    (tourNames === "7day" && "7th") ||
                    (tourNames === "10day" && "11th")}
                </p>
                <span>Trip Code: {tripCode}</span>
              </div>
              <div className="__table">
                <table>
                  <tr>
                    <th colspan="2"></th>
                    <th colspan="2">Package:</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      Adult - {pax} x{price}
                    </td>
                    <td colspan="2">
                      {(selectCountry === "Malaysia" && "MRY") ||
                        (selectCountry === "Singapore" && "SGD") ||
                        (selectCountry === "Other" && "$")}{" "}
                      {totalCost}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      Single Supplement -{" "}
                      {supplement ? true && 1 : 0 || supplementNo}
                    </td>
                    <td colspan="2">
                      {(selectCountry === "Malaysia" && "MRY") ||
                        (selectCountry === "Singapore" && "SGD") ||
                        (selectCountry === "Other" && "$")}{" "}
                      {singalSupplementCost || supplementCost}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      Children 6 to 12 - ({children} X {price / 2})
                    </td>
                    <td colspan="2">
                      {" "}
                      {(selectCountry === "Malaysia" && "MRY") ||
                        (selectCountry === "Singapore" && "SGD") ||
                        (selectCountry === "Other" && "$")}{" "}
                      {childrenTotalCost}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Children Below 6y - {infant}</td>
                    <td colspan="2">Free</td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td
                      colspan="2"
                      style={{ color: "#f37002", fontWeight: "600" }}
                    >
                      Total Payable :{" "}
                      {(selectCountry === "Malaysia" && "MRY") ||
                        (selectCountry === "Singapore" && "SGD") ||
                        (selectCountry === "Other" && "$")}{" "}
                      {total}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td className="total" colspan="2">
                      Advance Payment (10%) :{" "}
                      <em>
                        {(selectCountry === "Malaysia" && "MRY") ||
                          (selectCountry === "Singapore" && "SGD") ||
                          (selectCountry === "Other" && "$")}{" "}
                        {payableAmount}
                      </em>{" "}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourCheckuot;
