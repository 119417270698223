import React, { useEffect, useState } from "react";
import "./offers.scss";
import { FaSquareCheck } from "react-icons/fa6";
import TopDestinations from "../../components/topDestinations/TopDestinations";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Clock from "../../components/timer/Clock";
import DayFiveClock from "../../components/timer/DayFiveClock";
import DaySevenClock from "../../components/timer/DaySevenClock";
import DayTenClock from "../../components/timer/DayTenClock";
import axios from "axios";
import { IoIosClose } from "react-icons/io";

const Offers = () => {
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [date,setDate] = useState("")

  const onEditClick = (id) => {
    navigate(`/tour-checkout/${id}`);
    window.scrollTo(0, 0);
  };

  // console.log(price);

  const handleOpen = (days) => {
    setShow(!show);
    setDate(days);
    // console.log(days);
  };

  // console.log("date",date);
 

  return (
    <>
      {show && date === "day5" && (
        <div className="__mw">
          <div className="select_country">
            <div className="select_wrp">
              <div className="select_img">
                <img src={require("../../img/5_Banner.jpg")} alt="" />
              </div>
              <h2>
                {" "}
                <em className="spFont">Select The Country</em> You're From
              </h2>
              <div className="select_wrp_in">
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("2299,735,Malaysia,5day")}
                >
                  Malaysia
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("699,225,Singapore,5day")}
                >
                  Singapore
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("499,170,Other,5day")}
                >
                  Other
                </div>
              </div>
              <div className="close" onClick={handleOpen}>
                <IoIosClose size={30} />
              </div>
            </div>
          </div>
        </div>
      )}

      {show && date === "day7" && (
        <div className="__mw">
          <div className="select_country">
            <div className="select_wrp">
              <div className="select_img">
                <img src={require("../../img/7_Banner.jpg")} alt="" />
              </div>
              <h2>
                {" "}
                <em className="spFont">Select The Country</em> You're From
              </h2>
              <div className="select_wrp_in">
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("3249,1040,Malaysia,7day")}
                >
                  Malaysia
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("925,315,Singapore,7day")}
                >
                  Singapore
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("699,240,Other,7day")}
                >
                  Other
                </div>
              </div>
              <div className="close" onClick={handleOpen}>
                <IoIosClose size={30} />
              </div>
            </div>
          </div>
        </div>
      )}
      {show && date === "day10" && (
        <div className="__mw">
          <div className="select_country">
            <div className="select_wrp">
              <div className="select_img">
                <img src={require("../../img/10_Banner.jpg")} alt="" />
              </div>
              <h2>
                {" "}
                <em className="spFont">Select The Country</em> You're From
              </h2>
              <div className="select_wrp_in">
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("3699,1450,Malaysia,10day")}
                >
                  Malaysia
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("1065,440,Singapore,10day")}
                >
                  Singapore
                </div>
                <div
                  className="sub_btn"
                  onClick={() => onEditClick("899,335,Other,10day")}
                >
                  Other
                </div>
              </div>
              <div className="close" onClick={handleOpen}>
                <IoIosClose size={30} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="__fw" style={{ backgroundColor: "#F6F7F9" }}>
        <div className="__mw">
          <div className="off_hed">
            <small className="subTag">Pricing for 3 plans</small>
            <h1>
              <em className="spFont">Join Our Group Tours:</em> <br /> The
              Ultimate Sri Lankan Adventure
            </h1>
            <p>
              Explore all advantages of our pricing plans and choose your
              perfect one.
            </p>
          </div>

          <div className="offer_wrp">
            <div className="offer_card">
              <div className="select_img">
                <img src={require("../../img/5day.jpg")} alt="" />
              </div>
              <div className="offer_head">
                <span>
                  5 Days Tour <br />{" "}
                  {/* <small>
                    Every Month 5 <sup>th</sup>{" "}
                  </small> */}
                </span>
                {/* <span className="most">most profitable </span> */}
              </div>
              <div className="offer_price" id="499">
                <span>
                  $499 <em>Per Person</em>
                </span>
                <div className="offer_price_country">
                  <small>2299 MYR </small>
                  <small>699 SGD </small>
                </div>
                <hr />
              </div>
              <div className="__list">
                <ul>
                  <small>Itinerary</small>
                  <li>
                    <span>
                      <em> Day 01</em> Arrival → Negombo Stay <br />
                      <strong>Hotel: Hotel J</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 02</em> Hiriwadunna Trekking Village → Jeep Safari
                      Minneriya National Park → Habarana Stay{" "}
                      <strong>Hotel: Wewa Addara – Sigiriya</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em> Day 03</em> Sigiriya Palace → Dambulla Rock Temple →
                      Ayurvedic Herble Garden → Kandy Stay Evening Cultural Show
                      → Kandy City Tour → Temple Of Tooth <br />
                      <strong> Hotel: Thilanka Queens</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 04</em> Tea Plantation Tea Factory → Ramboda Falls
                      → Nuwara Eliya Leisure City Tour <br />
                      <strong>Hotel: Heaven Seven Hotel</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 05 </em>Colombo City Tour Sightseeing → Departure
                    </span>
                  </li>
                  <div className="line"></div>
                </ul>

                <div className="timer">
                  <p>
                    Every Month 5 <sup>th</sup>{" "}
                  </p>
                  {/* <h3>
                    <DayFiveClock />
                  </h3> */}
                </div>
                <div onClick={() => handleOpen("day5")} className="sub_btn">
                  Get started{" "}
                </div>
                <div className="suplement">
                  <strong>Single Supplement $170</strong>
                </div>
              </div>
            </div>

            <div className="offer_card_sp" id="899">
              <div className="select_img">
                <img src={require("../../img/10day.jpg")} alt="" />
              </div>
              <div className="offer_head">
                <span>
                  10 Days Tour <br />{" "}
                  {/* <small>
                    Every Month 10 <sup>th</sup>{" "}
                  </small>{" "} */}
                </span>
              </div>
              <div className="offer_price">
                <span>
                  $899 <em>Per Person</em>
                </span>
                <div className="offer_price_country">
                  <small>3699 MYR </small>
                  <small>1065 SGD </small>
                </div>
                <hr />
              </div>
              <div className="__list">
                <ul>
                  <small>Itinerary</small>
                  <li>
                    <span>
                      <em> Day 01</em> Arrival → Negombo Stay <br />
                      <strong>Hotel: Hotel J</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 02</em> Jeep Safari Minneriya National Park →
                      Habarana Stay <br />
                      <strong>Hotel: Wewa Addara – Sigiriya</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em> Day 03</em> Sigiriya Palace → Hiriwadunna Trekking
                      Village → Habarana Stay <br />
                      <strong> Hotel: Wewa Addara – Sigiriya</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 04</em> Polonnaruwa Tour → Habarana Stay <br />
                      <strong>Hotel: Wewa Addara – Sigiriya</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 05 </em>Dambulla Rock Temple → Ayurvedic Herble
                      Garden → Kandy Stay Evening Cultural Show <br />
                      <strong> Hotel: Thilanka Hotel</strong>
                    </span>
                  </li>
                  <li>
                    <span>
                      <em>Day 06 </em>Kithulgala Water Rafting → Kandy City Tour
                      → Temple Of Tooth <br />
                      <strong> Hotel: Thilanka Hotel</strong>
                    </span>
                  </li>
                  <li>
                    <span>
                      <em>Day 07 </em>Tea Plantation Tea Factory → Ramboda Falls
                      → Nuwara Eliya Leisure City Tour <br />
                      <strong> Hotel: Heaven Seven Hotel</strong>
                    </span>
                  </li>
                  <li>
                    <span>
                      <em>Day 08 </em>Nuwara Eliya Leisure <br />
                      <strong> Hotel: Heaven Seven Hotel</strong>
                    </span>
                  </li>
                  <li>
                    <span>
                      <em>Day 09 </em>Transfer To Leisure In Beach Resort →
                      Colombo Beach Stay <br />
                      <strong> Hotel: Berjaya Hotel Colombo</strong>
                    </span>
                  </li>
                  <li>
                    <span>
                      <em>Day 10 </em>Day 10 Colombo City Tour Sightseeing →
                      Departure
                    </span>
                  </li>
                </ul>

                <div className="timer">
                  <p>
                    {" "}
                    Every Month 10 <sup>th</sup>{" "}
                  </p>
                  {/* <h3 style={{ color: "white" }}>
                    <DayTenClock />
                  </h3> */}
                </div>
                <div
                  // onClick={handleOpen}
                  onClick={() => handleOpen("day10")}
                  className="sub_btn"
                >
                  Get started{" "}
                </div>
                <div className="suplement">
                  <strong>Single Supplement $335</strong>
                </div>
              </div>
            </div>

            <div className="offer_card">
              <div className="select_img">
                <img src={require("../../img/7day.jpg")} alt="" />
              </div>
              <div className="offer_head">
                <span>
                  7 Days Tour <br />{" "}
                  {/* <small>
                    Every Month 7 <sup>th</sup>{" "}
                  </small> */}
                </span>
                <span className="most">most profitable </span>
              </div>
              <div className="offer_price">
                <span>
                  $699 <em>Per Person</em>
                </span>
                <div className="offer_price_country">
                  <small>3249 MYR </small>
                  <small>925 SGD </small>
                </div>
                <hr />
              </div>
              <div className="__list">
                <ul>
                  <small>Itinerary</small>
                  <li>
                    <span>
                      <em> Day 01</em> Arrival → Negombo Stay <br />
                      <strong>Hotel: Hotel J</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 02</em> Jeep Safari Minneriya National Park →
                      Habarana Stay <br />
                      <strong>Hotel: Wewa Addara – Sigiriya</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em> Day 03</em> Sigiriya Palace → Hiriwadunna Trekking
                      Village → Polonnaruwa Tour → Habarana Stay
                      <br />
                      <strong> Hotel: Hotel Queens</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 04</em> Dambulla Rock Temple → Ayurvedic Herble
                      Garden → Kandy Stay Evening Cultural Show → Kandy City
                      Tour → Temple Of Tooth
                      <br />
                      <strong>Hotel: Thilanka Hotel</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 05 </em>Tea Plantation Tea Factory → Ramboda Falls
                      → Nuwara Eliya Leisure City Tour
                      <br />
                      <strong>Hotel: Heaven Seven Hotel</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 06 </em>Transfer To Leisure In Beach Resort →
                      Kithulgala Water Rafting → Colombo <br />
                      <strong> Hotel: Berjaya Hotel Colombo</strong>
                    </span>
                  </li>
                  <div className="line"></div>
                  <li>
                    <span>
                      <em>Day 07 </em>Colombo City Tour Sightseeing → Departure
                    </span>
                  </li>
                </ul>
                <div className="timer">
                  <p>
                    Every Month 7 <sup>th</sup>{" "}
                  </p>
                  {/* <h3>
                    <DaySevenClock />
                  </h3> */}
                </div>
                <div onClick={() => handleOpen("day7")} className="sub_btn">
                  Get started{" "}
                </div>
                <div className="suplement">
                  <strong>Single Supplement $240</strong>
                </div>
              </div>
            </div>
          </div>
          <div className="include_wrp">
            <div className="include">
              <ul>
                <small>Includes</small>

                <li>
                  <FaSquareCheck className="icon" /> Warm Welcome{" "}
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Star Hotel Accomodation
                  With Breakfast Dinner
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Transport By Luxury Bus
                  With Guide Service
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Entrance Fees
                </li>
                <li>
                  <FaSquareCheck className="icon" /> 4*4 Jeep Safari
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Unesco Sites
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Leisure Attraction,
                  Unforgetable Adventire
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Double Room Sharing
                  Accomodation And Option To Single Room Stay
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Each Tour Picup On One
                  Location Drop To Airport
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Suvinior At End Of Tour
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Children Under 11 Year Free
                  Sharing With Parents
                </li>
              </ul>
            </div>
            <div className="include">
              <ul>
                <small>Not Includes</small>
                <li>
                  <FaSquareCheck className="icon" /> Return Air Tickets
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Medical and insurance of
                  any kind.
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Lunch
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Entrance fee not mentioned
                  above & Optional activities, Personal nature Expenses i.e.
                  Meals, Tipping etc.
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Any expenses arising out of
                  unforeseen circumstances like flight delay/cancellation/hike
                  in fare, strike, or any other natural calamities
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Camera Fee at Monuments
                </li>
                <li>
                  <FaSquareCheck className="icon" /> Gratuities or tips
                </li>
              </ul>
            </div>
          </div>
          <div className="activities ">
            <em>Optional Activities </em>
            <ul>
              <li>
                <p>Ayurveda Spa</p>
              </li>
              <li>
                <p>Wood Carving Factory</p>
              </li>
              <li>
                <p>Herbal Garden</p>
              </li>
            </ul>
          </div>
          <div className="remark">
            <em>Remark</em>

            <ul>
              <li>
                {" "}
                * Quote is subject to availability, and all reservations are
                subject to availability at the time of booking and price <br />
                fluctuations will be added before the booking.
              </li>
              <li>
                {" "}
                * The Standard check-out time in Sri Lanka is 12 noon | Check-in
                02.00 pm.
              </li>
              <li>
                {" "}
                * Rates do not include Eve dinners or High-peak supplements.
              </li>
              <li>
                {" "}
                * Payment method (advance 20% by bank transfer, online credit
                card / balance 80% should be paid in cash USD).
              </li>
              <li>
                {" "}
                * Entrance fee NON-Refundable. Cancellation Policy refer our
                website
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div cla>
        <TopDestinations />
      </div>
    </>
  );
};

export default Offers;
